<template>
  <div class="border rounded-xl p-4 flex- flex-col gap-6">
    <h1 class="text-gray-800 font-bold text-justify">{{ title }}</h1>

    <BaseTextarea
      required="true"
      label="Descripción"
      v-model="description"
      :disabled="lectureMode"
      maxlength="1000"
    />
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "Title" },
    value: { type: String, default: "" },
    lectureMode: { type: Boolean, default: false },
  },
  mounted() {
    this.description = this.value;
  },
  data() {
    return {
      description: "",
    };
  },
  watch: {
    description: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$emit("description", this.description);
      }
    },
  },
};
</script>
