<template>
  <Accordion header="Formulación de la recomendación">
    <div class="border rounded-xl p-4 shadow-sm">
      <div class="w-1/2 xl:w-1/3">
        <BaseDate
          required="true"
          label="Fecha de formulación"
          v-model="formulation_date"
          :disabled="lectureMode"
        />
      </div>
      <div class="w-11/12 grid grid-cols-1 gap-1" v-if="loadFiles.length > 0">
        <h1 class="text-left text-gray-400 font-bold text-sm mt-5 mb-0">
          Archivos de soporte precargados
        </h1>
        <BaseDownload
          v-for="(option, index) in loadFiles"
          :value="option.file_name"
          :key="index"
          :url_download="option.file"
        />
      </div>
      <DragDrop
        v-if="!lectureMode"
        label="Anexar archivos de soporte:"
        buttonLabel="SELECCIONE SUS ARCHIVOS"
        id="step6-files"
        :fileList="files"
        @files-handler="setFiles($event)"
        maxMB="20"
      /></div
  ></Accordion>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { Accordion },
  data() {
    return {
      formulation_date: "",
      files: [],
      loading: false,
      loadFiles: [],
      oldFormulationDate: "",
    };
  },
  created() {
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        6
      );
      if (data) {
        this.oldFormulationDate = this.formulation_date;
        this.formulation_date = data.formulation_date;
        this.loadFiles = data.files;
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    setFiles(files) {
      this.files = files;
    },
    async saveStep() {
      if (
        this.oldFormulationDate !== this.formulation_date &&
        this.formulation_date != "" &&
        this.files.length > 0
      ) {
        let { data, error } = await this.$api.recommendationSteps(
          this.$route.query.recommendation_code,
          6,
          {
            form: { formulation_date: this.formulation_date },
            files: this.files,
          }
        );
        if (data) {
          this.showToast("success", "Guardando como borrador...");
          this.files = [];
          this.getStep(false);
        } else if (error) {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
        }
      } else {
        this.showToast("success", "Guardando como borrador...");
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    if (!this.lectureMode) this.saveStep();
  },
  watch: {
    finishing: function (value) {
      if (value) {
        this.saveStep();
      }
    },
    saveSignal: function (value) {
      if (value) {
        this.saveStep();
      }
    },
  },
};
</script>
