<template>
  <Accordion
    header="Producto o productos y motivos que sustentan la formulación de la recomendación"
  >
    <Products_Reasons
      @products="setProducts($event)"
      :lectureMode="lectureMode"
      @on-error="onErrorStep"
    />
  </Accordion>
</template>

<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
import Products_Reasons from "@/components/Dashboard/FCO/Recommendations/FormSteps/Products_Reasons.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { Accordion, Products_Reasons },
  data() {
    return {
      new_products: [],
      del_products: [],
    };
  },

  created() {},
  methods: {
    setProducts(products) {
      this.new_products = products?.new_products;
      this.del_products = products?.del_products;
    },
    async saveStep() {
      if (this.new_products.length > 0 || this.del_products.length > 0) {
        const errs = this.validateDataBeforeSave()
        if (errs.length) {
          this.onErrorStep(errs);
          return false;
        }
        let { error, data } = await this.$api.recommendationStep1(
          this.$route.query.recommendation_code,
          1,
          { new_products: this.new_products, del_products: this.del_products }
        );
        if (data) {
          this.showToast("success", "Guardando como borrador...");
        } else if (error) {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
        }
      }
    },
    validateDataBeforeSave() {
      const errors = [];
      const validation = ["", null, undefined]
      const that = this
      this.new_products.map(p => {
        if (validation.includes(p.product)) {
          const m = "Por favor selecciona un producto.";
          errors.push(m)
          that.showToast('info', m)
        }
        if (p.reasons.length < 1) {
          const m = "Por favor selecciona al menos un motivo.";
          errors.push(m)
          that.showToast('info', m)
        }
        if (p.reasons.includes(99)) {
          if (validation.includes(p.other_name)) {
            const m = "Por favor añade un título.";
            errors.push(m)
            that.showToast('info', m)
          }
          if (validation.includes(p.other_description)) {
            const m = "Por favor añade una descripción.";
            errors.push(m)
            that.showToast('info', m)
          }
        }
      });
      return errors
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    onErrorStep(errors) {
      const msgs = errors.map(e => `En el Paso 1: ${e}`)
      this.$emit('on-step-error', msgs, 0);
    },
  },
  beforeDestroy() {
    if (!this.lectureMode) this.saveStep();
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        if (this.new_products.lenth > 0 || this.del_products.length > 0)
          this.saveStep();
        else this.showToast("info", "Guardando como borrador...");
      }
    },
  },
};
</script>
