<template>
  <Accordion header="Causa raíz">
    <SimpleStep
      title="Puntualmente cuál es la causa o hecho originador del hallazgo que ha sido identificado en el DCF y que motiva la emisión de la recomendación o la vocería"
      @description="root_cause = $event"
      v-if="!loading"
      :value="root_cause"
      :lectureMode="lectureMode" />
    <div
      v-if="loading"
      class="spinner w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin></div
  ></Accordion>
</template>
<script>
import SimpleStep from "./SimpleStep.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { SimpleStep, Accordion },
  data() {
    return {
      root_cause: "",
      loading: false,
      oldRootCause: "",
    };
  },
  created() {
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        3
      );
      if (data) {
        this.root_cause = data.root_cause;
        this.oldRootCause = this.root_cause;
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    async saveStep() {
      if (this.oldRootCause !== this.root_cause && this.root_cause !== "") {
        let { data, error } = await this.$api.recommendationSteps(
          this.$route.query.recommendation_code,
          3,
          { root_cause: this.root_cause }
        );
        if (data) {
          this.showToast("success", "Guardando como borrador...");
        } else if (error) {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
        }
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    this.saveStep();
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        this.saveStep();
        this.getStep(false);
      }
    },
  },
};
</script>
