<template><RecommentadionsFormSteps /></template>
<script>
import RecommentadionsFormSteps from "@/components/Dashboard/FCO/Recommendations/RecommendationsFormSteps.vue";

export default {
  components: { RecommentadionsFormSteps },
  name: "recommendationsFormSteps",
  data() {
    return {};
  },
};
</script>
