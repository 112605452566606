<template>
  <div>
    <div
      v-if="!loading"
      class="border rounded-xl p-6 relative mb-5"
      v-for="(product, idx) in products"
      :key="idx"
    >
      <a-button
        v-if="!lectureMode"
        @click="removeProduct(idx)"
        icon="delete"
        class="border-none right-0 top-0 mt-3 mr-3 text-xl absolute"
      ></a-button>
      <BaseSelect
        v-if="optionsProducts[idx] && optionsProducts[idx].length > 0"
        required="true"
        :disabled="lectureMode || products[idx].noEdit"
        label="Seleciona el producto"
        :selectOptions="optionsProducts[idx]"
        v-model="products[idx].product"
      />
      <BaseMultiSelect
        :required="true"
        :disabled="lectureMode || products[idx].noEdit"
        v-if="optionsReasons.length > 0"
        title="Seleccione los motivos"
        placeholder="Seleccionar"
        :data="optionsReasons"
        :valuesSelected="products[idx].loadReasons"
        label="name"
        @notify-objects="addReason(idx, $event)"
        class="relative"
      />
      <div v-if="validateOtherReason(idx)">
        <BaseInput
          required="true"
          label="Titulo"
          v-model="products[idx].other_name"
          :disabled="lectureMode || products[idx].noEdit"
        />
        <BaseTextarea
          required="true"
          :disabled="lectureMode || products[idx].noEdit"
          label="Descripción"
          rows="3"
          maxlength="1000"
          v-model="products[idx].other_description"
        />
      </div>
    </div>

    <div
      class="border p-3 flex gap-4 rounded-xl m-0"
      @click="addProduct()"
      v-if="!lectureMode"
    >
      <a-button
        type="primary"
        shape="circle"
        icon="plus"
        id="add-product"
        size="large"
      ></a-button>
      <label class="text-ld text-gray-700 my-auto font-bold"
        >Añadir producto</label
      >
    </div>
    <div
      v-if="loading"
      class="spinner w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
export default {
  props: { lectureMode: { type: Boolean, default: false } },
  data() {
    return {
      id_company: "",
      products: [],
      delProducts: [],
      optionsReasons: [],
      optionsProducts: [],
      othersReasons: [],
      borrar: [],
      loading: false,
    };
  },
  created() {
    this.id_company = this.$route.query.id_company;
    this.getProducts();
    this.getReasons();
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        1
      );
      if (data) {
        this.products = data.results.map((product) => {
          return {
            ...product,
            product: product.product.toString(),
            loadReasons: product.reasons,
            noEdit: true,
          };
        });
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    addProduct() {
      this.getProducts();
      const errors = this.validateDataBeforeAdd();
      if (errors.length) {
        this.$emit('on-error', errors)
        return false;
      }

      this.products.push({
        product: "",
        reasons: [],
        other_name: null,
        other_description: null,
        recommendation: this.$route.query.recommendation_code,
      });
    },
    removeProduct(idx) {
      const product = this.products.splice(idx, 1);
      this.delProducts.push(parseInt(product[0].id));
      this.getProducts();
      this.$forceUpdate();
    },
    async getProducts() {
      let productList = await this.$store.dispatch("form/loadProductsOptions", {
        company: this.id_company,
      });
      this.borrar = productList;

      if (this.products.length > 0) {
        let existsProductsSelected = this.products.filter(
          (item) => item.product != ""
        );
        if (existsProductsSelected.length > 0) {
          let actualProductsId = this.products.map(
            (product) => product.product
          );

          let newSelectList = [...productList];

          actualProductsId.forEach((id) => {
            if (id != "") {
              let el = newSelectList.findIndex((search) => search.id == id);
              newSelectList.splice(el, 1);
            }
          });

          this.products.forEach((product, idx) => {
            this.optionsProducts[idx] = [...newSelectList];
            let el = productList.find(
              (search) => search.id == product?.product
            );
            if (el) this.optionsProducts[idx].push(el);
          });
        } else
          this.products.forEach((product, idx) => {
            this.optionsProducts[idx] = productList;
          });
      }
      this.$forceUpdate();
    },
    async getReasons() {
      this.optionsReasons = await this.$store.dispatch(
        "form/loadReasonsOptions",
        {
          company: this.id_company,
        }
      );
      this.optionsReasons = this.optionsReasons.map((reason) => {
        if (reason.id === 99) {
          reason.name = "otro";
        }
        return reason;
      });
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    addReason(idx, reasons) {
      this.products[idx].reasons = reasons.map((reason) => {
        return reason.id;
      });

      this.$forceUpdate();
    },
    validateOtherReason(idx) {
      if (this.products[idx].reasons.length > 0)
        return this.products[idx].reasons.includes(99);
      return null;
    },
    validateDataBeforeAdd() {
      const errors = [];
      const validation = ["", null, undefined]
      const that = this
      this.products.map(p => {
        if (validation.includes(p.product)) {
          const m = "Por favor selecciona un producto.";
          errors.push(m)
          that.showToast('info', m)
        }
        if (p.reasons.length < 1) {
          const m = "Por favor selecciona al menos un motivo.";
          errors.push(m)
          that.showToast('info', m)
        }
        if (p.reasons.includes(99)) {
          if (validation.includes(p.other_name)) {
            const m = "Por favor añade un título.";
            errors.push(m)
            that.showToast('info', m)
          }
          if (validation.includes(p.other_description)) {
            const m = "Por favor añade una descripción.";
            errors.push(m)
            that.showToast('info', m)
          }
        }
      });
      return errors
    }
  },
  computed: {},
  watch: {
    products: {
      handler: function () {
        this.getProducts();
        this.$emit("products", {
          del_products: this.delProducts,
          new_products: this.products.filter((product) => !product?.id),
        });
      },
      deep: true,
    },
  },
};
</script>
