<template>
  <Accordion header="Recomendación del DCF">
    <SimpleStep
      title="Debe ser específica para prevenir y mitigar el impacto del hallazgo entre los consumidores financieros de la entidad y de esta forma propender por la correcta y segura prestación del servicio"
      @description="description = $event"
      v-if="!loading"
      :value="description"
      :lectureMode="lectureMode" />
    <div
      v-if="loading"
      class="spinner w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin></div
  ></Accordion>
</template>
<script>
import SimpleStep from "./SimpleStep.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { SimpleStep, Accordion },
  data() {
    return {
      description: "",
      oldDescription: "",
      loading: false,
    };
  },
  created() {
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        5
      );
      if (data) {
        this.description = data.description;
        this.oldDescription = this.description;
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    async saveStep() {
      if (this.oldDescription !== this.description && this.description !== "") {
        let { data, error } = await this.$api.recommendationSteps(
          this.$route.query.recommendation_code,
          5,
          { description: this.description }
        );
        if (data) {
          this.showToast("success", "Guardando como borrador...");
        } else if (error) {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
        }
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    this.saveStep();
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        this.saveStep();
        this.getStep(false);
      }
    },
  },
};
</script>
