<template>
  <Accordion header="Evento o hallazgo">
    <SimpleStep
      v-if="!loading"
      :value="event"
      title="Descripción del hallazgo o situación identificada que impida ofrecer un trato justo a los consumidores financieros de la entidad"
      @description="event = $event"
      :lectureMode="lectureMode" />
    <div
      v-if="loading"
      class="spinner w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin></div
  ></Accordion>
</template>
<script>
import SimpleStep from "./SimpleStep.vue";
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  props: {
    finishing: { type: Boolean, default: false },
    saveSignal: { type: Boolean, default: false },
    lectureMode: { type: Boolean, default: false },
  },
  components: { SimpleStep, Accordion },
  data() {
    return {
      event: "",
      oldEvent: "",
      loading: false,
    };
  },
  created() {
    this.getStep();
  },
  methods: {
    async getStep(loading = true) {
      this.loading = loading;
      let { error, data } = await this.$api.getSteps(
        this.$route.query.recommendation_code,
        2
      );
      if (data) {
        this.event = data.event;
        this.oldEvent = this.event;
        this.loading = false;
      }
      if (error) {
        this.showToast("error", "Ocurrió un error obteniendo la información.");
        this.loading = false;
      }
    },
    async saveStep() {
      if (this.oldEvent !== this.event && this.event !== "") {
        let { data, error } = await this.$api.recommendationSteps(
          this.$route.query.recommendation_code,
          2,
          { event: this.event }
        );
        if (data) {
          this.showToast("success", "Guardando como borrador...");
        } else if (error) {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
        }
      }
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  beforeDestroy() {
    this.saveStep();
  },
  watch: {
    saveSignal: function (value) {
      if (value) {
        this.saveStep();
        this.getStep(false);
      }
    },
  },
};
</script>
